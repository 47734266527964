<template>
  <div class="community-page" v-show="!requestLoading">
    <CommunityDetail
      v-if="communityInfo && communityInfo.id"
      :communityInfo="communityInfo"
      :nftLevel="nftLevel"
    ></CommunityDetail>
    <CommunityList ref="communityList" v-else :list="list" :nftLevel="nftLevel" @searchById="searchById"></CommunityList>
  </div>
</template>

<script>
import CommunityDetail from './components/CommunityDetail.vue'
import CommunityList from './components/CommunityList.vue'
import {
  queryCommunityList,
  queryCommunityInfo
} from '../../services/community'
import { queryMyWallet } from '@/services/wallet'
export default {
  name: '',
  components: {
    CommunityList,
    CommunityDetail
  },
  data () {
    return {
      requestLoading: true,
      params: {
        name: '',
        pageSize: 100,
        pageNumber: 1
      },
      list: [],
      communityInfo: '', // 社区详情
      nftLevel: 0 // nft等级
    }
  },
  methods: {
    async init () {
      this.requestLoading = true
      await this.getCommunityInfo()
      this.requestLoading = false
      if (!this.communityInfo) {
        this.getList()
        this.getIsNft()
      } else {
        // this.$router.push('/communityIndex')
      }
    },
    async getCommunityInfo () {
      const resp = await queryCommunityInfo()
      if (!resp.success) return
      this.communityInfo = resp.result
    },
    async getList () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 2)
      const resp = await queryCommunityList(this.params)
      this.$emit('changeLoading', false)
      if (!resp.success) return
      this.list = resp.result.records || []
    },
    async searchById (name) {
      if (!name) return this.getList()
      const params = {
        name: name,
        pageSize: 100,
        pageNumber: 1
      }
      const resp = await queryCommunityList(params)
      if (!resp.success) return
      const list = resp.result.records || []
      if (list.length > 1 || list.length === 0) {
        this.list = list
      } else {
        const item = list[0]
        this.$refs.communityList.handleToDetail(item)
      }
    },
    // 获取是否有骑士卡
    async getIsNft () {
      const resp = await queryMyWallet()
      if (!resp.success) return
      this.nftLevel = resp.result.nftLevel || 0
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.community-page {
  padding: 13px 13px 80px 13px;
  box-sizing: border-box;
  font-size: 12px;
}
</style>
